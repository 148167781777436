<template>
    <div class="addtask-page">
        <div class="page-title">
            <span class="name">分配任务</span>
        </div>
        <div class="select">
            <!-- 选择成员 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">选择人员</a-col>
                <a-col flex="300px">
                    <a-select
                      style="width: 100%"
                      placeholder="选择录入人员"
                      v-model="selectRealName"
                    >
                        <a-select-option  @click="handleChangeStaff(item.userId)" :value="item.realName" v-for="(item) in staffList" :key="item.userId">
                           {{item.realName}}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 分配工作 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">分配工作</a-col>
                <a-col flex="250px">
                    <div>
                        <a-dropdown :trigger="['click']" >
                          <div class="ant-dropdown-link" @click.prevent="selectArea">
                            <span v-if="!selectedAreaList.length">选择地区</span>
                            <span v-else style="color:#595959">已选择地区（{{selectedAreaList.length}}）</span>
                            <a-icon type="down" />
                         </div>
                          <div slot="overlay" class="regionList">
                                <div v-if="areaList.length>0" class="dim-scroll" @click.stop>
                                    <vue-scroll :ops="ops" style="height:100%">
                                        <a-tree
                                                v-model="checkedKeysArea"
                                                checkable
                                                :tree-data="areaList"
                                                checkStrictly
                                                :load-data="e=>{return onLoadData(e,'REGION',areaSheetId)}" 
                                                :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                        >
                                            <template #title="node">
                                                <a-dropdown :trigger="['contextmenu']">
                                                    <span>{{ node.dimName }}</span>
                                                    <template #overlay>
                                                        <a-menu @click="({key:menuKey}) => onContextMenuClick(menuKey,node,'REGION',areaSheetId)">
                                                            <a-menu-item key="5">全选</a-menu-item>
                                                            <a-menu-item key="6">取消全选</a-menu-item>
                                                            <a-menu-item key="1">选择同级</a-menu-item>
                                                            <a-menu-item key="2">取消同级</a-menu-item>
                                                            <a-menu-item key="3" v-if="node.isParent">选择子项</a-menu-item>
                                                            <a-menu-item key="4" v-if="node.isParent">取消子项</a-menu-item>
                                                        </a-menu>
                                                    </template>
                                                </a-dropdown>
                                            </template>
                                        </a-tree>
                                    </vue-scroll>
                                </div>
                                <div v-else>
                                    <a-empty style="line-height:300px" description="暂无数据" />
                                </div>
                            </div>
                        </a-dropdown>
                    </div>
                </a-col>
                <a-col flex="250px">
                    <div>
                        <a-dropdown :trigger="['click']" >
                          <div class="ant-dropdown-link" @click.prevent="selectTime">
                            <span v-if="!selectedTimeList.length">选择时间</span>
                            <span v-else style="color:#595959">已选择时间（{{selectedTimeList.length}}）</span>
                            <a-icon type="down" />
                         </div>
                          <div slot="overlay" class="regionList">
                                <div class="dim-scroll" @click.stop>
                                    <vue-scroll :ops="ops" style="height:100%">
                                        <a-tree
                                                v-model="checkedKeysTime"
                                                checkable
                                                checkStrictly
                                                :load-data="e=>{return onLoadData(e,'TIME',timeSheetId)}"
                                                :tree-data="timeList"
                                                :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                        >
                                            <template #title="node">
                                                <a-dropdown :trigger="['contextmenu']">
                                                    <span>{{ node.dimName }}</span>
                                                    <template #overlay>
                                                        <a-menu @click="({key:menuKey}) => onContextMenuClick(menuKey,node,'TIME',timeSheetId)">
                                                            <a-menu-item key="5">全选</a-menu-item>
                                                            <a-menu-item key="6">取消全选</a-menu-item>
                                                            <a-menu-item key="1">选择同级</a-menu-item>
                                                            <a-menu-item key="2">取消同级</a-menu-item>
                                                            <a-menu-item key="3" v-if="node.isParent">选择子项</a-menu-item>
                                                            <a-menu-item key="4" v-if="node.isParent">取消子项</a-menu-item>
                                                        </a-menu>
                                                    </template>
                                                </a-dropdown>
                                            </template>
                                        </a-tree>
                                    </vue-scroll>
                                </div>
                            </div>
                        </a-dropdown>
                    </div>
                </a-col>
            </a-row>
            </div>
            <!-- 时间区间 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">时间区间</a-col>
                <a-col flex="200px">
                    <a-date-picker v-model="startTime" valueFormat="YYYY-MM-DD" @change="onChangeStart" placeholder="开始时间" allowClear/>
                </a-col>
                <a-col flex="200px">
                    <a-date-picker v-model="endTime" valueFormat="YYYY-MM-DD" @change="onChangeEnd" placeholder="结束时间" allowClear/>
                </a-col>
            </a-row>
            </div>
            <!-- 内容审核 顶级管理权限下此项空 -->
            <!-- 选择成员 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">内容审核</a-col>
                <a-col flex="300px">
                    <a-select
                      style="width: 100%"
                      placeholder="选择审核人员"
                      v-model="selectContentRealName"
                    >
                        <a-select-option  @click="handleChangeCheckStaff(item.userId)" :value="item.realName" v-for="(item) in staffList" :key="item.userId">
                           {{item.realName}}
                        </a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            </div>
            <!-- 工作说明 -->
            <div class="select-item">
                <a-row type="flex" :gutter="[16,32]">
                <a-col flex="120px">工作说明</a-col>
                <a-col flex="auto">
                    <a-textarea v-model="memo" cols="100" rows="5"></a-textarea>
                </a-col>
            </a-row>
            </div>
        </div>
        <div class="btns">
            <a-button type="primary" @click="inputs_get_addJob" v-if="!$route.query.detailList">提交</a-button>
            <a-button type="primary" @click="inputs_get_updateJob" v-else>修改</a-button>
            <a-button type="button" @click="$router.go(-1)">取消</a-button>
        </div>
    </div>
</template>

<script>
import { messageTips } from '@/until/tools';
import config from '@/until/configs';
const treeData = [
  {
    title: '0-0', 
    key: '0-0',
  },
  {
    title: '0-1',
    key: '0-1',
  },
  {
    title: '0-2',
    key: '0-2',
  },
];
export default {
    data() {
        return {
            treeData,
            staffList:[],//人员列表
            selectStaffId:"",//选中录入人员
            selectCheckStaffId:"",//选中审核人员
            areaList:[],//地区列表
            timeList:[],//时间列表
            selectedAreaList:[],//已选择地区
            selectedTimeList:[],//已选择时间
            startTime:"",//开始时间
            endTime:"",//结束时间
            ops: config.vueScrollSetting,
            checkedKeysTime: [],
            checkedKeysArea: [],
            cubeId:this.$route.query.cubeId,//选中库
            jobName:"",
            parentJobId:"",
            selectRealName:undefined,
            selectContentRealName:undefined,
            teamIds:'',
            memo:"",//工作说明
            teamName:undefined,//
            cubeName:undefined,//
            jobId:"",
            editJobId:'',
            timeSheetId:'',
            areaSheetId:'',
            showArea:true,
            nearest:'请选择'
        }
    },
    created() {
        if(this.$route.query.detailList) {
            let val =JSON.parse(this.$route.query.detailList)
                    Object.assign(this,{
                    selectStaffId:val.inputUserId,
                    selectCheckStaffId:val.checkUserId,
                    jobName:val.jobName,
                    jobId:val.parentJobId,//修改获取维度时使用父级jobId
                    editJobId:val.jobId,//点击修改时使用自己jobId
                    cubeId:val.cubeId,
                    startTime:val.startDate,
                    endTime:val.endDate,
                    memo:val.memo,
                    parentJobId:val.parentJobId,
                    checkedKeysArea:val.regionCodes?val.regionCodes.split(','):[],
                    checkedKeysTime:val.timeCodes?val.timeCodes.split(','):[],
                    teamIds:val.teamIds,
                    
                })
        }
        
    },
    computed:{
        userInfo() {
            return this.$store.getters.getUserInfo;
        },
    },
    methods:{
        onContextMenuClick(menuKey,node,codeName,sheetId){
            console.log(menuKey,node);
            if(menuKey==5){//全选
                var params = {}
                if(codeName=='TIME'){
                     params = {dimType:codeName,sheetId:this.timeSheetId}
                }else{
                     params = {dimType:codeName,sheetId:this.areaSheetId}
                }
                if(this.jobId) {
                    params.jobId = this.jobId;
                }
                this.$http.getAllDimCodes(params).then(res=>{
                    if(res.dimensionCodeList) {
                        if(codeName=='TIME'){
                            this.checkedKeysTime = res.dimensionCodeList.map(item=>item.dimCode);
                        }else{
                            this.checkedKeysArea=res.dimensionCodeList.map(item=>item.dimCode);
                        }
                    } else {
                        messageTips(res.message,1);
                    }
                });
            }else if(menuKey==6){//取消全选
                if(codeName=='TIME'){
                    this.selectedTimeList=[]//真正处理完的数据
                    this.checkedKeysTime=[]//监视并为selectetimelist处理数据
                }else{
                    this.selectedAreaList=[]
                    this.checkedKeysArea=[]
                }
            }else if(menuKey==1){//选择同级
                if(codeName=='TIME'){
                    this.timeList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                if(!this.selectedTimeList.includes(i2.dimCode)){
                                    this.selectedTimeList.push(i2.dimCode);
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                if(!this.selectedTimeList.includes(i.dimCode)){
                                    this.selectedTimeList.push(i.dimCode)
                                }
                            }else{
                                if(!this.selectedTimeList.includes(i.dimCode)){
                                    this.selectedTimeList.push(i.dimCode)
                                }
                            }
                        }
                    })
                }else{
                    this.areaList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                if(!this.selectedAreaList.includes(i2.dimCode)){
                                    this.selectedAreaList.push(i2.dimCode);
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                if(!this.selectedAreaList.includes(i.dimCode)){
                                    this.selectedAreaList.push(i.dimCode)
                                }
                            }else{
                                if(!this.selectedAreaList.includes(i.dimCode)){
                                    this.selectedAreaList.push(i.dimCode)
                                }
                            }
                        }
                    })
                }
            }else if(menuKey==2){//取消同级
                if(codeName=='TIME'){
                    this.timeList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                let index=this.selectedTimeList.indexOf(i2.dimCode)
                                if(index!==-1){
                                    this.selectedTimeList.splice(index,1)
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                let index=this.selectedTimeList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedTimeList.splice(index,1)
                                }
                            }else{
                                let index=this.selectedTimeList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedTimeList.splice(index,1)
                                }
                            }
                        }
                    })
                }else{
                    this.areaList.filter((i)=>{
                        if(i.dimCode==node.parentId&&!node.isParent){
                            i.children.filter(i2=>{
                                let index=this.selectedAreaList.indexOf(i2.dimCode)
                                if(index!==-1){
                                    this.selectedAreaList.splice(index,1)
                                }
                            })
                        }else{
                            if(node.isParent||node.parentId==-1){
                                let index=this.selectedAreaList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedAreaList.splice(index,1)
                                }
                            }else{
                                let index=this.selectedAreaList.indexOf(i.dimCode)
                                if(index!==-1){
                                    this.selectedAreaList.splice(index,1)
                                }
                            }
                        }
                    })
                }
            }else if(menuKey==3){//选择子项
                let params = {
                    jobId:this.jobId,
                    parentCode:node.eventKey,
                    cubeId:this.cubeId,
                    dimType:codeName,
                    sheetId:sheetId
                }
                if(codeName=='TIME'){
                    this.$http.getDimensionChildren(params).then(res=>{
                        if(res.success){
                            res.dimensionList.filter((item)=>{
                                if(this.selectedTimeList.includes(item.dimCode)){
                                    return false
                                }else{
                                    this.selectedTimeList.push(item.dimCode)
                                }
                            })
                        }
                    })
                }else{
                    this.$http.getDimensionChildren(params).then(res=>{
                        if(res.success){
                            res.dimensionList.filter((item)=>{
                                if(this.selectedAreaList.includes(item.dimCode)){
                                    return false
                                }else{
                                    this.selectedAreaList.push(item.dimCode)
                                }
                            })
                        }
                    })
                }
            }else{//4取消子项
                if(node.isParent){
                    let params = {
                        jobId:this.jobId,
                        parentCode:node.eventKey,
                        cubeId:this.cubeId,
                        dimType:codeName,
                        sheetId:sheetId
                    }
                    if(codeName=='TIME'){
                        this.$http.getDimensionChildren(params).then(res=>{
                            if(res.success){
                                res.dimensionList.filter((item)=>{
                                    if(!this.selectedTimeList.includes(item.dimCode)){
                                        return false
                                    }else{
                                        this.selectedTimeList.splice(this.selectedTimeList.indexOf(item.dimCode),1)
                                    }
                                })
                            }
                        })
                    }else{
                        this.$http.getDimensionChildren(params).then(res=>{
                            if(res.success){
                                res.dimensionList.filter((item)=>{
                                    if(!this.selectedAreaList.includes(item.dimCode)){
                                        return false
                                    }else{
                                        this.selectedAreaList.splice(this.selectedAreaList.indexOf(item.dimCode),1)
                                    }
                                })
                            }
                        })
                    }
                }
            }
        },
        onLoadData(treeNode,codeName,sheetId) {
            return new Promise(resolve => {
                // if (treeNode.dataRef.children) {
                //     resolve();
                //     return;
                // }
                let params = {
                    jobId:this.$route.query.jobId,
                    parentCode:treeNode.eventKey,
                    cubeId:this.cubeId,
                    dimType:codeName,
                    sheetId:sheetId
                }
                this.$http.getDimensionChildren(params).then(res=>{
                    res.dimensionList.forEach(item=>{
                        if(!item.isParent) {
                            item.isLeaf = true;//去掉箭头
                        }
                        // if(!item.chkDisabled) {
                        //     item.disabled = true;//禁掉响应
                        // }
                        item['title']=item.dimName
                        item['key']=item.dimCode
                    })
                    treeNode.dataRef.children = res.dimensionList
                    if(codeName=='REGION'){
                        this.areaList = [...this.areaList];
                    }else{
                        this.timeList=[...this.timeList]
                    }
                    resolve();                        
                    });
            });
        },
        //获取组员
        inputs_get_getTeamUser(){
            this.$http.inputs_get_getTeamUser().then(res=>{
                if(res.success) {
                    this.staffList=res.list;
                    if(this.selectStaffId){
                        for(var i=0;i<res.list.length;i++){
                            if(this.selectStaffId===res.list[i].userId){
                                this.selectRealName=res.list[i].realName;
                            }
                        }
                    }
                    if(this.selectCheckStaffId){
                        for(var j=0;j<res.list.length;j++){
                            if(this.selectCheckStaffId===res.list[j].userId){
                                this.selectContentRealName=res.list[j].realName;
                            }
                        }
                    }
                }
            })
        },
        //选中录入人员
        handleChangeStaff(value) {
            this.selectStaffId=value;
        },
        //选中审核人员
        handleChangeCheckStaff(value) {
            this.selectCheckStaffId=value;
        },
        //#region
        //获取地区/时间维度信息
        getDimensions(val){
            let params = {
                cubeId: this.cubeId
            }
            if(this.$route.query.jobId){//分配
                params.jobId=this.$route.query.jobId
            }
            if(this.$route.query.detailList){//编辑
                params.jobId=this.jobId
            }
            if(val==1){
                if(this.areaList.length>0) {
                    return false;
                }
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        if(res.dataletBO.allDimensions.dimensionVOList[2]&&res.dataletBO.allDimensions.dimensionVOList[2].showName=='地区'){
                            const data=res.dataletBO.allDimensions.dimensionVOList[2].objList
                            this.areaSheetId=res.dataletBO.sheetInfo.sheetId
                            if(data!==undefined){
                                const temp = data.map((item)=>{
                                if(!item.isParent) {
                                    item.isLeaf = true;//去掉箭头
                                }
                                // if(!item.chkDisabled) {
                                //     item.disabled = true;//禁掉响应
                                // }
                                    return item
                                })
                                this.areaList=temp
                            }else{
                                this.areaList=[]
                            }
                        }else{
                            this.showArea=false
                        }
                    }
                })
            }else{
                //禁止选子项后再走接口
                if(this.timeList.length>0) {
                    return false;
                }
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        const data=res.dataletBO.allDimensions.dimensionVOList[1].objList
                        this.timeSheetId=res.dataletBO.sheetInfo.sheetId
                        if(data!==undefined){
                            const temp = data.map((item)=>{
                            if(!item.isParent) {
                                item.isLeaf = true;//去掉箭头
                            }
                            // if(!item.chkDisabled) {
                            //     item.disabled = true;//禁掉响应
                            // }
                                return item
                            })
                            this.timeList=temp
                        }else{
                            this.timeList=[]
                        }
                    }
                })
            }
        },
        //#endregion
        //选择地区
        selectArea(){
            this.getDimensions(1)
        },
        //选择时间
        selectTime(){
            this.getDimensions(2)
        },
        //分配任务
        inputs_get_addJob(){
            let params ={
                jobName:this.jobName||this.$route.query.jobName,//任务名
                inputUserId:this.selectStaffId,//录入id
                checkUserId:this.selectCheckStaffId,//审核id
                memo:this.memo,//工作说明
                regionCodes:this.selectedAreaList.join(),//地区code
                timeCodes:this.selectedTimeList.join(),//时间code
                startDate:this.startTime||'',
                endDate:this.endTime||'',
                parentJobId:this.parentJobId||this.$route.query.parentJobId
            }
            this.$http.inputs_get_addJob(params).then(res=>{
                if(res.success){
                    messageTips(res.message,3)
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 500);
                }else{
                    messageTips(res.message,2)
                }
            })
        },
        //修改任务提交
        inputs_get_updateJob(){
            let params ={
                jobId:this.editJobId,
                jobName:this.jobName,//任务名
                teamIds:this.teamIds||'',//组id
                // cubeId:this.cubeId,//库id
                memo:this.memo,//工作说明
                regionCodes:this.selectedAreaList.join(),
                timeCodes:this.selectedTimeList.join(),
                startDate:this.startTime||'',
                endDate:this.endTime||'',
                parentJobId:this.parentJobId,
                inputUserId:this.selectStaffId,
                checkUserId:this.selectCheckStaffId
            }
            this.$http.inputs_get_updateJob(params).then(res=>{
                if(res.success){
                    messageTips(res.message,3)
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 500);
                }else{
                    messageTips(res.message,2)
                }
            })
        },
        onChangeStart(value) {
            this.startTime=value
        },
        onChangeEnd(value) {
            this.endTime=value
        },
  },
    watch: {
        cubeId(value) {
            if(value) {
                this.nearest = '请选择';
            }
        },
        checkedKeysArea(val) {
            if(val.checked){
                this.selectedAreaList=val.checked
            }else{
                this.selectedAreaList=val
            }
        },
        checkedKeysTime(val) {
            if(val.checked){
                this.selectedTimeList=val.checked
            }else{
                this.selectedTimeList=val
            }
        },
    },
    mounted(){
        this.inputs_get_getTeamUser()
        this.getDimensions(1)
    },
}
</script>

<style lang="scss" scoped>
@import '../../scss/teamAddTask.scss';
</style>
